import React from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import ReactTooltip from "react-tooltip"

const MatelasTestimonialsWrapper = styled.div`
  display: flex;
  background: ${({ page }) => (page === "home" ? "#fff" : "#d7edf6")};
  width: 100%;
  align-items: center;
`

const MatelasTestimonialsContainer = styled.div`
  display: flex;
  max-width: 1200px;
  width: 100%;
  margin: 0px auto;
  padding: 15px 20px;
  justify-content: space-between;

  @media (max-width: 767px) {
    flex-wrap: nowrap;
  }

  @media (max-width: 440px) {
    padding: 5px 15px;
  }
`

const MatelasTestimonials = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    flex-basis: 50%;
    padding: 10px 5px;
  }

  @media (max-width: 360px) {
    justify-content: center;
  }
`

const MatelasTestimonialBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  @media (max-width: 767px) {
    flex-direction: column;
    min-width: auto;
  }

  .__react_component_tooltip {
    background: ${({ page }) => (page === "home" ? "#fff" : "#1b2a56")};
    color: ${({ page }) => (page === "home" ? "#262626" : "#fff")};
    max-width: 300px;
    border-radius: 40px 0px;
    border-width: 0px;
    padding: 20px;
  }

  .__react_component_tooltip h6 {
    margin: 0px 0px 10px 0px;
    font-family: "BebasNeueBold";
    font-size: 24px;
  }

  .__react_component_tooltip p {
    margin: 0px;
    font-family: "Gotham Book";
    font-size: 14px;
  }

  .__react_component_tooltip.show {
    opacity: 1;
  }
  .__react_component_tooltip.place-left:after {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    right: -6px;
    top: 50%;
    margin-top: -4px;
    border-left-color: ${({ page }) => (page === "home" ? "#fff" : "#1b2a56")};
    border-left-style: solid;
    border-left-width: 6px;
  }
  .__react_component_tooltip.place-right:after {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    left: -6px;
    top: 50%;
    margin-top: -4px;
    border-right-color: ${({ page }) => (page === "home" ? "#fff" : "#1b2a56")};
    border-right-style: solid;
    border-right-width: 6px;
  }
  .__react_component_tooltip.place-top:after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    bottom: -6px;
    left: 50%;
    margin-left: -8px;
    border-top-color: ${({ page }) => (page === "home" ? "#fff" : "#1b2a56")};
    border-top-style: solid;
    border-top-width: 6px;
  }
  .__react_component_tooltip.place-bottom:after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    top: -6px;
    left: 50%;
    margin-left: -8px;
    border-bottom-color: ${({ page }) =>
      page === "home" ? "#fff" : "#1b2a56"};
    border-bottom-style: solid;
    border-bottom-width: 6px;
  }
`

const MatelasTestimonialImage = styled.img`
  width: 35px;
  height: 35px;
  margin-bottom: 0;
  object-fit: contain;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`

const DesktopTestimonialText = styled.div`
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  font-size: 14px;
  line-height: 1.2;
  font-family: Museo;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;

  @media (max-width: 767px) {
    display: none;
  }
`

const MobileTestimonialText = styled.div`
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  font-size: 14px;
  line-height: 1.2;
  font-family: Museo;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
  display: none;

  @media (max-width: 767px) {
    display: block;
    margin-left: 0px;
    text-align: center;
  }

  @media (max-width: 400px) {
    font-size: 13px;
  }

  @media (max-width: 350px) {
    font-size: 12px;
  }
`

const MatelasTestimonialSection = ({ intl, data, page }) => {
  return (
    data && (
      <MatelasTestimonialsWrapper page={page}>
        {data.list && (
          <MatelasTestimonialsContainer>
            {data.list.map((item, index) => {
              return (
                <MatelasTestimonials key={index.toString()}>
                  <MatelasTestimonialBox page={page}>
                    {item.image && (
                      <MatelasTestimonialImage
                        className="lazyload"
                        data-src={item.image}
                        data-tip={item.popup_text}
                        data-html={true}
                        data-for={`matelas_testimonial_section` + index}
                      />
                    )}
                    {item.text && (
                      <DesktopTestimonialText
                        dangerouslySetInnerHTML={{
                          __html: item.text,
                        }}
                        data-tip={item.popup_text}
                        data-html={true}
                        data-for={`matelas_testimonial_section` + index}
                      />
                    )}
                    {item.mobile_text && (
                      <MobileTestimonialText
                        dangerouslySetInnerHTML={{
                          __html: item.mobile_text,
                        }}
                        data-tip={item.popup_text}
                        data-html={true}
                        data-for={`matelas_testimonial_section` + index}
                      />
                    )}
                    <ReactTooltip
                      id={`matelas_testimonial_section` + index}
                      html={true}
                      place={index == 0 ? "right" : "top"}
                    />
                  </MatelasTestimonialBox>
                </MatelasTestimonials>
              )
            })}
          </MatelasTestimonialsContainer>
        )}
      </MatelasTestimonialsWrapper>
    )
  )
}

export default injectIntl(MatelasTestimonialSection)
